body {
  margin: 0;
  font-family: "Gilroy", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Gilroy", source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Gilroy-Regular";
  src: local("Gilroy-Regular"),
    url(./assets/fonts/gilroy/Gilroy-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Gilroy-Bold";
  src: local("Gilroy-Bold"),
    url(./assets/fonts/gilroy/Gilroy-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Gilroy-Medium";
  src: local("Gilroy-Medium"),
    url(./assets/fonts/gilroy/Gilroy-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Gilroy-Medium-Italic";
  src: local("Gilroy-Medium-Italic"),
    url(./assets/fonts/gilroy/Gilroy-MediumItalic.ttf) format("truetype");
}

@font-face {
  font-family: "Gilroy-Light";
  src: local("Gilroy-Light"),
    url(./assets/fonts/gilroy/Gilroy-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Gilroy-Semibold";
  src: local("Gilroy-Semibold"),
    url(./assets/fonts/gilroy/Gilroy-SemiBold.ttf) format("truetype");
}
